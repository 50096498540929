export default [
  {
    title: 'Printers',
    icon: 'PrinterIcon',
    children: [
      {
        title: 'Manage Printers',
        route: 'browse-printer',
        // acl
        action: 'browse',
        resource: 'Printer',
      },
      {
        title: 'Invite Printer',
        route: 'create-printer',
        // acl
        action: 'create',
        resource: 'Printer',
      },
    ],
  },
]
